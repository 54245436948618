.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 80px;
}

.project-title {
    position: relative;
}

.project-title h1 {
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}

.project-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-25px); /* Move the image up by 10px */
    z-index: -1;
}

.project-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.project-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
}

.project-item img {
    box-sizing: border-box;
    width: 419px;
    height: 280px;
    transition: 0.3s;
    object-fit:cover
}

.project-item:hover img {
    transform: scale(1.10);
    border: 4px solid #ff00ff;
    border-radius: 10px;
    transition: 0.3s;
    object-fit:cover
}

.project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: white;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.project-item:hover .project-overlay {
    opacity: 1;
}

.project-name {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px; /* Reduced margin to fit subtitle better */
}

.project-subtitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px; /* Space between subtitle and tech stack */
    opacity: 0.9; /* Slight transparency to distinguish from main title */
}

.project-tech {
    font-size: 18px;
    font-weight: 500;
}

.project-showmore {
    display: flex;
    gap: 15px;
    border-radius: 100px;
    border: 2px solid white;
    padding: 10px 30px;
    font-size: 22px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: 0.6s;
    cursor: pointer;
}

.project-showmore:hover{
    transform: scale(1.05);
    border-color: #B415FF;
    background-image: linear-gradient(45deg, #3f0028 , #582300);
}


@media (max-width : 768px) {

.project-title h1
{
    font-size: 50px;
}

.project-title img
{
    max-width: 200px;
    transform: translateX(-10px);
}

.project-container
{
    grid-template-columns: 1fr; /* Change to single column */
    gap: 30px; /* Adjust gap for smaller screens */
    margin-bottom: 40px;    
}

.project-item
{
    min-width: 300px;
}



}