.certification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 40px 80px;
}

.certification-title {
  position: relative;
}

.certification-title h1 {
  padding: 0px 20px;
  font-size: 60px;
  font-weight: 600;
  word-wrap: break-word;
}
.certification-title img {
  position: relative;
  bottom: 0;
  right: 0;
  transform: translateY(-80px) translateX(100px);
  z-index: -1;
}

.certification-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 100%;
  margin-bottom: 32px;
}

.certification-format {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  border-radius: 10px;
  border: 2px solid white;
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  height: auto;
  min-height: 150px;
  box-sizing: border-box;
}

.certification-left {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}

.certification-left img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.certification-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  flex-grow: 1;
}

.certification-format:hover {
  border: 2px solid #B415FF;
  background-image: linear-gradient(45deg, #3f0028, #582300);
  transform: scale(1.05);
  transition: 0.4s;
}

.certification-format h1 {
  font-size: 32px;
  font-weight: 800;
  background: linear-gradient(270deg, #DF8908 50%, #B415FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.certification-format p {
  color: white;
  font-size: 15px;
  line-height: 28px;
  width: 100%;
}

.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 22px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  padding: 20px;
  height: 100%;
  min-height: 150px;
}

.show-more-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 80px;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  padding: 20px;
  min-height: 150px;
  width: 100%;
}

.show-more-button p {
  font-size: 40px;
  font-weight: 800;
  background: linear-gradient(270deg, #DF8908 50%, #B415FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .certification {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: 0px 10px;
    padding: 30px; /* Ensure no margin on the sides */
  }

  .certification-title h1 {
    font-size: 40px;
    padding: 0px 10px;
  }

  .certification-title img {
    position: relative;
    bottom: 0;
    right: 0;
    transform: translateY(-60px) translateX(50px);
    z-index: -1;
  }

  .certification-container {
    position: relative;
    grid-template-columns: 1fr; /* Single column layout */
    gap: 30px; /* Control the gap between items */
    margin: 0;
    padding: 0; /* Remove any padding */
    width: 100%; /* Ensure it takes full width */
  }

  .certification-format {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 20px;
    margin-bottom: 0; /* Ensure no bottom margin on last element */
  }

  .certification-left img {
    width: 60px;
    height: 60px;
  }

  .certification-right h1 {
    font-size: 24px;
  }

  .certification-right p {
    display: block; /* Make the date visible */
    font-size: 20px; /* Adjust the font size for mobile */
    line-height: 20px; /* Adjust the line height */
    margin-top: 5px; /* Add some spacing between the title and date */
    margin-left: 2px;
    color: #ffffff; /* Keep the color white */
  }

  .show-more-button p {
    font-size: 30px;
  }

  /* Remove extra space after the last element */
  .certification-container > .show-more:last-child {
    margin-bottom: 0;
  }

  /* Add this to ensure the container does not overflow */
  .certification-container {
    box-sizing: border-box;
  }
}