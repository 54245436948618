.experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 72px;
    margin: 40px 80px;
}

.experience-title {
    position: relative;
}

.experience-title h1 {
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}

.experience-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-25px); /* Move the image up by 10px */
    z-index: -1;
}

.experience-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns grid */
    gap: 32px;
    width: 100%;
}

.experience-format {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
    border-radius: 10px;
    border: 2px solid white;
    transition: 0.4s;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    height: auto; /* Ensure grid items expand based on content */
    min-height: 300px; /* Set a minimum height to prevent items from shrinking too much */
    box-sizing: border-box; /* Include padding and border in the height */
}

.experience-left {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 20px;
}

.experience-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    flex-grow: 1; /* Allow content to stretch */
}

.experience-format:hover {
    border: 2px solid #B415FF;
    background-image: linear-gradient(45deg, #3f0028 , #582300);
    transform: scale(1.05);
    transition: 0.4s;
}

.experience-format h3 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.experience-format h1 {
    font-size: 32px;
    font-weight: 800;
    background: linear-gradient(270deg, #DF8908 50%, #B415FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.experience-format h2 {
    font-size: 32px;
    font-weight: 800;
    background: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.experience-format p {
    color: white;
    font-size: 15px;
    line-height: 28px;
    width: 100%;
}

@media (max-width : 768px) {

    .experience {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
        margin: 40px 30px;
    }

    .experience-title h1
    {
        font-size: 50px;
    }

    .experience-title img
    {
        transform: translateY(-10px);
    }

    .experience-container
    {
        left: 0%;
        grid-template-columns: 1fr; /* Change to single column */
        gap: 30px; /* Adjust gap for smaller screens */
        margin-bottom: 20px;
    }
    
}