.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 80px;
  }
  
  .contact-title {
    position: relative;
  }

  .contact-title h2
  {
    font-size: 80px;
  }
  
  .contact-title h1 {
    padding: 0px 30px;
    font-size: 50px;
    font-weight: 600;
    background-image: radial-gradient( circle 763px at 18.3% 24.1%,  rgba(255,249,137,1) 7.4%, rgba(226,183,40,1) 58.3% );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .contact-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(-220px) translateY(-50px); /* Move the image up by 10px */
    z-index: -1;
  }

  .contact-section
  {
    display: flex;
    gap : 150px
  }

  .contact-left
  {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .contact-left h1
  {
    text-align: left;
    font-size: 80px;
    font-weight: 700;
    background-image: linear-gradient( 68.1deg,  rgba(196,69,69,1) 9.2%, rgba(255,167,73,0.82) 25%, rgba(253,217,82,0.82) 43.4%, rgba(107,225,108,0.82) 58.2%, rgba(107,169,225,0.82) 75.1%, rgba(153,41,243,0.82) 87.3% );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .contact-left p 
  {
    max-width: 550px;
    color: azure;
    font-size: 20px;
    line-height: 35px;
    align-items: left;
    text-align: left;
  }

  .contact-details
  {
    display: flex;
    flex-direction: column;
    gap : 30px;
    color : azure;
    font-size: 22px;
    
  }

  .contact-detail
  {
    display: flex;
    align-items: center;
    gap : 20px;
  }

  .contact-right
  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap : 30px
  }

  .contact-right label 
  {
    color: aliceblue;
    font-size: 22px;
    font-weight: 500;

  }

  .contact-right input
  {
    border: none;
    width: 700px;
    height: 78px;
    padding-left: 20px;
    border-radius: 10px;
    background: #32323c;
    color: #A0A0A0;
    font-size: 20px;
  }

  .contact-right textarea
  {
    width: 670px;
    border: none;
    padding: 25px;
    border-radius: 10px;
    background: #32323c;
    color: #A0A0A0;
    font-size: 20px;
  }

  .contact-submit
  {
    border: none;
    color: white;
    border-radius: 50px;
    background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 100%);
    font-size: 22px;
    padding: 20px 60px;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 0.3s;
    font-family: montserrat;

  }

  .contact-submit:hover
  {
    transform: scale(1.1);
    transition: 0.3s;
    border: 2px solid white;

  }

  @media (max-width: 768px) {

    .contact
    {
      margin: 10%;
    }



    .contact h1
    {
      text-align: center;
    
      font-size: 40px;
    font-weight: 600;
    }

    .contact-title {
        position: relative;
        margin-bottom: 0;
      }

    .contact-title h1
    {
      margin: 10px;
      margin-bottom: -20px;
      font-size : 30px;
    }

     .contact-title h2
     {
        display: none;
     } 

    .contact-title img {
       display: none;
      }

    .contact-section {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start;
      gap: 40px; /* Add some space between the sections */
    }

    .contact-left h1
    {
      font-size: 50px;
    }
  
    .contact-right {
      align-items:first baseline;
      max-width: 100%; /* Ensure the form takes the full width */
    }

  .contact-right label 
  {
    color: aliceblue;
    font-size: 20px;
    font-weight: 500;

  }


  
    .contact-right input
    {
      width : 110%
    }
    .contact-right textarea 
    {  
      width: 100%; /* Make input fields take full width */
    }
  

    .contact-submit
  {
    position: relative;
    transform: translateX(25px);

  }


  }