.footer
{
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
    gap : 30px;
}

.footer hr
{
    width: 100%;
}

.footer-top
{
    display: flex;
    justify-content: space-between;
}

.footer-top-left p
{
    text-align: left;
    font-size: 20px;
    max-width: 400px;
}

.footer-top-left img
{
    position: relative;
    left: -65px;
    top: 50px;
    max-width: 300px;
}


.footer-top-right 
{
    display: flex;
    align-items: center;
    gap: 35px;
}

.footer-bottom
{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.footer-bottom-left p
{
    text-align: left;
}

.footer-bottom-right
{
    display: flex;
    gap : 50px;
    margin-bottom: 50px;

}

@media (max-width : 768px) {


.footer
{
    margin: 50px 170px;
    display: flex;
    flex-direction: column;
    gap : 30px;
    align-items: flex-start;
    margin-left: 15%;
}

.footer hr
{
    position: relative;
    width: 100%;
}

.footer-top-left
{
    display: flex;
    flex-direction: column;
}

.footer-top-left img
{
    max-width: 200px;
    position: relative;
    transform: translateX(55px) translateY(-10px);
}



.footer-top-left p
{
    font-size: 15px;
    text-align: flex start;
}

.footer-bottom
{
    display: flex;
    flex-direction: column;
    min-width: 250px;
    font-size: 18px;
}

.footer-bottom-left 
{
    
}

.footer-bottom-right
{
    display: flex;
    flex-direction: column;
    gap : 10px;
    margin-bottom: 50px;

}


.footer-bottom-left p
{
    text-align: center;
}




    
}