/* Default navbar style */
.navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8); /* Slightly transparent by default */
    transition: background-color 0.3s ease; /* Smooth transition */
    margin-top: -35px;
}
.navbar img {
    max-width: 300px;
}

#clickMenu {
    display: none;
}

.navbar .nav-menu li:hover {
    cursor: pointer;
}

/* Transparent navbar style */
.navbar.transparent {
    background-color: rgba(0, 0, 0, 0.5); /* More transparent on scroll */
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 60px;
    font-size: 20px;
}

.nav-connect {
    padding: 20px 40px;
    border-radius: 50px;
    background: linear-gradient(267deg, #DA7C25 0.36%, #B923E1 102.61%);
    font-size: 22px;
    cursor: pointer;
    transition: transform 0.5s;
}

.nav-connect:hover {
    transform: scale(1.05);
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.nav-menu li img {
    display: flex;
    margin: auto;
}

.anchor-link {
    text-decoration: none;
    color: white;
}

.nav-mob-close {
    display: none;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {

    .navbar {
        margin: 5px 20px;
        padding: 10px 20px;
        align-items: center;
        justify-content: center; /* Center content */
        width: 100%;
    }

    /* Hide the navigation items */
    .nav-menu, 
    #clickMenu {
        display: none;
    }

    /* Center the logo/image */
    .navbar img {
        max-width: 150px;
        margin: auto;
        min-width: 400px;
    }
}