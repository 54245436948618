.education
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap : 72px;
    margin:40px 80px;
}

.education-title
{
    position: relative;
}

.education-title h1
{
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;

}

.education-title img
{
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-25px); /* Move the image up by 10px */
    z-index: -1;

}
.education-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-bottom: 10px;
    align-items: center;
}

.education-format {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Add this to evenly space items */
    gap: 20px;
    padding: 60px;
    border-radius: 10px;
    border: 2px solid white;
    transition: 0.4s;
    cursor: pointer;
    min-height: 400px; /* Ensure all containers have the same height */
    text-align: center;
}

.education-format h3, 
.education-format h2, 
.education-format p {
    margin: 0;
    padding: 0;
}

.education-format h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px; /* Add margin to space out elements evenly */
}

.education-format h2 {
    font-size: 38px;
    font-weight: 800;
    background: linear-gradient(270deg, #DF8908 50%, #B415FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px; /* Add margin to space out elements evenly */
}

.education-format p {
    color: white;
    font-size: 25px;
    line-height: 40px;
    max-width: 300px;
    margin-bottom: 10px; /* Add margin to space out elements evenly */
}

.education-format:hover {
    border: 2px solid #B415FF;
    background-image: linear-gradient(45deg, #3f0028, #582300);
    transform: scale(1.05);
    transition: 0.4s;
}

@media (max-width : 768px) {


    .education
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
        margin: 40px 30px;
    }

    .education-title h1
    {
        font-size: 50px;

    }


    .education-container {
        grid-template-columns: 1fr; /* Change to single column */
        gap: 30px; /* Adjust gap for smaller screens */
        margin-bottom: 20px;
    }

    .education-format {
        max-width: 350px;
        max-height: 250px; /* Increase height to fit content */
        overflow: hidden; /* Hide overflow */
        padding: 20px; /* Adjust padding for smaller size */
    }

    .education-format h3
    {
        margin-top: 10px;
        font-size: 50px;
    }

    .education-format h2
    {
        font-size: 30px;

    }

    .education-format p {
        font-size: 18px; /* Reduce font size to fit */
        line-height: 24px;
    }

    


    
}