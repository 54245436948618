.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
    margin: 10px 50px;
    padding: 0 40px; /* Added padding */
}
.about-title {
    position: relative;
}

.about-title h1 {
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}

.about-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-25px); /* Move the image up by 10px */
    z-index: -1;
}
.about-sections {
    display: flex;
    gap: 56px;
    
}



.about-left img
{
    position: relative;
    top: 5%;
    max-width: 250px;
    border-radius: 10%;

}

.about-right {
    display: flex;
    flex-direction: column;
    
}

.about-para {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
}

.about-skills {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about-skill {
    display: flex;
    align-items: center;
    gap: 20px;
    transition: 0.3s;
}

.about-skill p {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.about-skill hr {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(264deg, #DF8908 -5.09%, #B415FF 106.28%);
    border: none;
    margin: 0;
}

.about-acheivements {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 48px;
}

.about-acheivement {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap : 10px;
    transition: 0.5s;
}

.about-acheivement h1 {
    font-size: 60px;
    font-weight: 700;
    background: linear-gradient(270deg, #DF8908 50.41% , #B415FF 90.25%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-acheivement p {
    font-size: 20px;
    font-weight: 500;
}

.about-skill:hover
{
    transform: scale(1.05);
    transition: 0.3s;
}

.about-acheivement:hover
{
    transform: scale(1.12);
    transition: 0.5s;
}

@media (max-width:740px) {

    .about
    {
        gap: 80px;
        margin: 20px 20px;
        align-items: center;
    }

    .about-title h1
    {
        position: relative;
        font-size : 50px;
        padding: 0;
        left: 5%;
    }

    .about-title img
    {
        width: 200px;
        right: -20px;
    }

    .about-sections
    {
        flex-direction: column;
        align-items: center;
    }


    .about-para
    {
        font-size: 18px;
        line-height: 32px;
    }

    .about-skill
    {
        gap:20px;
    }

    .about-skill p
    {
       
        font-size: 10px;
    }

    .about-skill hr
    {
        min-width: 10px
    }

    .about-acheivements
    {
     
        min-width: 300px;
        justify-content: space-around;
    }

    .about-acheivement
    {
        margin: 5%;
        gap : 10px;
    }

    .about-acheivement h1
    {
        font-size: 40px;
        font-weight: 700;
    }

    .about-acheivement p
    {
        text-align: center;
        font-size: 10px;
    }
    
}