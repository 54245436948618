.hero
{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap : 35px;
}

.hero img
{
    max-width: 300px;
    border-radius: 10%;
    margin-bottom: -45px;
}

.hero h1
{
    text-align: center;
    width: 70%;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: -2%;

}

.hero h1 span{
    background : linear-gradient(270deg, #DF8908 10% , #B415FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero h2
{
    font-size: 40px;
    margin-bottom: -1%;
    font-weight: 600;
}

.hero p{
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
}

.hero-action{
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;

}

.hero-connect
{
    padding: 25px 45px;
    border-radius: 50px;
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% );
    cursor: pointer;
}

.hero-resume
{
    padding: 25px 75px;
    border-radius: 50px;
    border: 2px solid #FFF;
    cursor: pointer;
}

.hero-connect:hover{
    border: 2px solid white;

}

.hero-resume:hover
{
    border-color: #B415FF;
}



@media (max-width : 768px) 
{

    .hero
    {
        margin-left: 18px;
    }
    .hero img
    {
        margin-bottom: 0px;
        width: 260px;
    }

    .hero h1
    {
        margin-top: 50px;
        width: 90%;
        font-size: 40px;
    }

    .hero p
    {
        width: 300px;
        font-size: 18px;
        line-height: 32px;
        margin: 10px 0px;
    };

    .hero-action
    {
        gap : 16px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 50px;
    }

    .hero-connect
    {
        padding : 20px 30px;
        border-radius: 40px;
    }

    .hero-resume
    {
        padding: 20px 30px;
        border-radius: 40px;
    }
    
}