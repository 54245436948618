.achievement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 80px;
}

.achievement-title {
    position: relative;
}

.achievement-title h1 {
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}
.achievement-title h2 {
    padding: 0px 30px;
    font-size: 40px;
    font-weight: 600;
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.achievement-title img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-25px) translateX(-220px);
    z-index: -1;
}

.achievement-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.achievement-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
}

.achievement-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}

.achievement-item:hover img {
    transform: scale(1.10);
    border-radius: 10px;
    transition: 0.3s;
}

.achievement-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: white;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
}

.achievement-item:hover .achievement-overlay {
    opacity: 1;
}

.achievement-name-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    transition: transform 0.3s ease;
}

.achievement-item:hover .achievement-name-overlay {
    transform: translateY(-10px);
}

.achievement-desc {
    font-size: 15px;
    font-weight: 500;
    opacity: 0.9;
    margin-top: 10px;
}

.achievement-showmore {
    display: flex;
    gap: 15px;
    border-radius: 100px;
    border: 2px solid white;
    padding: 10px 30px;
    font-size: 22px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: 0.6s;
    cursor: pointer;
}

.achievement-showmore:hover{
    transform: scale(1.05);
    border-color: #B415FF;
    background-image: linear-gradient(45deg, #3f0028 , #582300);
}

@media (max-width: 768px) {

    .achievement
    {
        margin: 10% 10%;
    }

    .achievement-title h2
    {

        margin: 5%;
        font-size: 30px;

    }

    .achievement-container {
      grid-template-columns: 1fr;
      gap: 45px;
      margin: 40px;
      width: 100%; /* Set width to full */
      max-width: 100%; /* Ensure it uses the full available width */
      padding: 0 10px; /* Add padding to reduce edge contact */
    }
  
    .achievement-item {
      width: 100%; /* Ensure each item uses the full container width */
    }
  
    .achievement-title h1 {
      font-size: 40px;
    }
  
    .achievement-title img {
      max-width: 200px ;
      transform: translateX(-10px) translateY(10px);
    }

    .achievement-overlay {
        opacity: 1; /* Set overlay to be visible by default */
        background-color: transparent; /* Make the background transparent initially */
        justify-content: flex-end; /* Align content at the bottom */
        padding: 10px; /* Adjust padding for spacing */
    }
    
    .achievement-overlay > *:not(.achievement-name-overlay) {
        display: none; /* Hide all elements except the title */
    }
    
    .achievement-item:hover .achievement-overlay {
        background-color: rgba(0, 0, 0, 0.7); /* Darken the background on hover */
        justify-content: center; /* Center content on hover */
    }
    
    .achievement-item:hover .achievement-overlay > * {
        display: flex; /* Show all elements on hover */
    }
  }